import { environmentVariables } from '../utils/environmentVariables'

type IntercomWindow = Window & {
  Intercom?: {
    (...args: Array<unknown>): void
  }
  intercomSettings?: unknown
}

export function loadIntercom(): void {
  const APP_ID = environmentVariables('INTERCOM_APP_ID')
  if (!APP_ID) return

  // copied and ported to ts from
  // https://developers.intercom.com/installing-intercom/docs/basic-javascript#single-page-app
  const w = window as IntercomWindow
  const ic = w.Intercom

  if (typeof ic === 'function') {
    ic('reattach_activator')
    ic('update', w.intercomSettings)
  } else {
    const d = document

    const i = function (): void {
      i.c(arguments) // eslint-disable-line prefer-rest-params
    }
    i.q = [] as unknown[]
    i.c = function (args: unknown) {
      i.q.push(args)
    }
    w.Intercom = i
    const l = function (): void {
      const s = d.createElement('script')
      s.classList.add('optanon-category-C0004')
      s.type = 'text/plain'
      s.async = true
      s.src = `https://widget.intercom.io/widget/${APP_ID}`
      const x = d.getElementsByTagName('script')[0]
      x.parentNode?.insertBefore(s, x)
    }
    if (document.readyState === 'complete') {
      l()
    } else {
      w.addEventListener('load', l, false)
    }
  }

  w.Intercom?.('boot', {
    app_id: environmentVariables('INTERCOM_APP_ID'),
  })
}
